var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "myinfo-table myinfo-table--cell-background mt-4",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "기업구분"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.kind) + " ")]), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "카테고리"
    }
  }, _vm._l(_vm.categories, function (category) {
    return _c('v-checkbox', {
      key: category._id,
      staticClass: "d-inline-block mr-3 mt-0",
      attrs: {
        "color": "primary",
        "label": category.name[_vm.locale],
        "value": category._id,
        "dense": "",
        "hide-details": ""
      },
      on: {
        "input": _vm.input
      },
      model: {
        value: _vm.me.category,
        callback: function ($$v) {
          _vm.$set(_vm.me, "category", $$v);
        },
        expression: "me.category"
      }
    });
  }), 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('common.기업명')
    }
  }, [_vm._v(" " + _vm._s(_vm.me.companyName[_vm.locale]) + " ")]), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "사업자등록번호"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.businessNumber,
      callback: function ($$v) {
        _vm.$set(_vm.me, "businessNumber", $$v);
      },
      expression: "me.businessNumber"
    }
  })], 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('common.주소')
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('common.시도'),
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.sido[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.sido, _vm.locale, $$v);
      },
      expression: "me.sido[locale]"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('common.시구군'),
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.gugun[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.gugun, _vm.locale, $$v);
      },
      expression: "me.gugun[locale]"
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }]
  }, [_c('v-text-field', {
    attrs: {
      "label": "상세주소",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.me, "companyAddress", $$v);
      },
      expression: "me.companyAddress"
    }
  })], 1)], 1)], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "수출기업여부"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.me.isExportCompany,
      callback: function ($$v) {
        _vm.$set(_vm.me, "isExportCompany", $$v);
      },
      expression: "me.isExportCompany"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "수출기업",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "비수출",
      "value": false
    }
  })], 1)], 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('mypage.CEO_name')
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.ceoName[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.ceoName, _vm.locale, $$v);
      },
      expression: "me.ceoName[locale]"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "대표자 연락처"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.ceoPhone,
      callback: function ($$v) {
        _vm.$set(_vm.me, "ceoPhone", $$v);
      },
      expression: "me.ceoPhone"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "대표자 이메일"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.ceoEmail,
      callback: function ($$v) {
        _vm.$set(_vm.me, "ceoEmail", $$v);
      },
      expression: "me.ceoEmail"
    }
  })], 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('mypage.company_profile')
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "accept": ".pdf,.xlsx,.jpg,.png"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.introductions[0][_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.introductions[0], _vm.locale, $$v);
      },
      expression: "me.introductions[0][locale]"
    }
  })], 1), _vm.me.introductions[0][_vm.locale] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.me.introductions[0][_vm.locale].name.split('/').pop()))]), _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.removeIntroduction(0, _vm.locale);
      }
    }
  }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1), _c('small', {
    staticClass: "d-block text-grey caption"
  }, [_vm._v(_vm._s(_vm.$t("mypage.company_profile_upload")))]), _c('div', {
    staticClass: "text-explain mb-2"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t("mypage.company_profile_warning")))])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--mypage"
  }, [_vm.scope.includes('seller') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.셀러 회원 마이페이지')
    }
  }) : _vm._e(), _vm.scope.includes('buyer') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.바이어 회원 마이페이지')
    }
  }) : _vm._e(), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "ct"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _vm.me ? _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "text-explain mb-5"
  }, [_c('p', {
    staticClass: "color-primary"
  }, [_vm._v(_vm._s(_vm.$t('mypage.company_edit_message1')) + " "), _c('strong', [_vm._v(_vm._s(_vm.$t('mypage.save')))]), _vm._v(" " + _vm._s(_vm.$t('mypage.company_edit_message2')))]), _c('small', [_vm._v(_vm._s(_vm.$t('mypage.company_profile_warning')))])]), _c('div', {
    staticClass: "mypage-contents__head"
  }, [_c('div', {
    staticClass: "tab-menu"
  }, _vm._l(_vm.list, function (item) {
    return _c('tab-item', _vm._b({
      key: item.id,
      model: {
        value: _vm.currentId,
        callback: function ($$v) {
          _vm.currentId = $$v;
        },
        expression: "currentId"
      }
    }, 'tab-item', item, false));
  }), 1)]), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('div', {
    staticClass: "table-caption-right"
  }, [_c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")]), _vm._v(" " + _vm._s(_vm.$t('common.required_fields')) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    staticClass: "myinfo-table myinfo-table--cell-background",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('myinfo-row', {
    attrs: {
      "label": "아이디"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.username) + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "담당자 이름"
    }
  }, [_c('v-text-field', {
    staticClass: "input",
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.name,
      callback: function ($$v) {
        _vm.$set(_vm.me, "name", $$v);
      },
      expression: "me.name"
    }
  })], 1), _c('myinfo-row', {
    attrs: {
      "label": "담당자 연락처"
    }
  }, [_c('div', {
    staticClass: "form-tel"
  }, [_c('v-select', {
    attrs: {
      "items": ['010', '011', '016', '017', '019'],
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone1", $$v);
      },
      expression: "me.phone1"
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('v-text-field', {
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.me.phone2 = _vm.me.phone2.replace(/[^0-9]/g, '');
      }
    },
    model: {
      value: _vm.me.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone2", $$v);
      },
      expression: "me.phone2"
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('v-text-field', {
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.me.phone2 = _vm.me.phone2.replace(/[^0-9]/g, '');
      }
    },
    model: {
      value: _vm.me.phone3,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone3", $$v);
      },
      expression: "me.phone3"
    }
  })], 1)]), _c('myinfo-row', {
    attrs: {
      "label": "담당자 이메일"
    }
  }, [_c('v-text-field', {
    staticClass: "input",
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.email,
      callback: function ($$v) {
        _vm.$set(_vm.me, "email", $$v);
      },
      expression: "me.email"
    }
  })], 1), _c('myinfo-row', {
    attrs: {
      "label": "비밀번호",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "",
      "value": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "비밀번호를 입력하세요."
    },
    model: {
      value: _vm.me.subPassword,
      callback: function ($$v) {
        _vm.$set(_vm.me, "subPassword", $$v);
      },
      expression: "me.subPassword"
    }
  }), _c('small', {
    staticClass: "d-block text-grey caption"
  }, [_vm._v("(영문대소문자/숫자/특수문자 조합, 8 - 16자)")])], 1), _c('myinfo-row', {
    attrs: {
      "label": "비밀번호 확인",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "",
      "value": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "비밀번호를 재입력하세요."
    },
    model: {
      value: _vm.me.subPassword2,
      callback: function ($$v) {
        _vm.$set(_vm.me, "subPassword2", $$v);
      },
      expression: "me.subPassword2"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "주소",
      "pointer": ""
    }
  }, [_c('div', {
    staticClass: "form-address"
  }, [_c('div', {
    staticClass: "form-address__postcode"
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": "우편번호",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.daumPostcode.open();
      }
    },
    model: {
      value: _vm.me.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.me, "postcode", $$v);
      },
      expression: "me.postcode"
    }
  }), _c('button', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.$refs.daumPostcode.open();
      }
    }
  }, [_vm._v("검색")])], 1), _c('v-text-field', {
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": "기본 주소를 입력해주세요.",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.daumPostcode.open();
      }
    },
    model: {
      value: _vm.me.address1,
      callback: function ($$v) {
        _vm.$set(_vm.me, "address1", $$v);
      },
      expression: "me.address1"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "placeholder": "상세 주소를 입력해주세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.address2,
      callback: function ($$v) {
        _vm.$set(_vm.me, "address2", $$v);
      },
      expression: "me.address2"
    }
  })], 1)])], 1)]), _vm.scope.includes('buyer') ? _c('buyer-info', {
    attrs: {
      "locale": _vm.locale
    },
    model: {
      value: _vm.me,
      callback: function ($$v) {
        _vm.me = $$v;
      },
      expression: "me"
    }
  }) : _vm._e(), _vm.scope.includes('seller') ? _c('seller-info', {
    attrs: {
      "locale": _vm.locale
    },
    model: {
      value: _vm.me,
      callback: function ($$v) {
        _vm.me = $$v;
      },
      expression: "me"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "bottom-button"
  }, [_c('button', {
    staticClass: "button button--border-primary"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.취소")))])]), _c('button', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.save
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.저장")))])])])], 1)]) : _vm._e()], 1)])]), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        return Object.assign(_vm.me, {
          postcode,
          address1: address
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
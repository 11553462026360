<template>
    <nav class="mypage-navigation-container">
        <ul class="mypage-navigation">
            <!-- <li class="mypage-navigation__list">
                <a href="#" :data-toggle="$route.path.indexOf('/mypage/order-') > -1 ? 'opened' : 'closed'" @click="accordionToggle($event); $event.preventDefault();" class="mypage-navigation__link"><i class="arrow" v-html="icon">{{icon}}</i><span>전체주문내역</span></a>
                <div class="mypage-navigation__sub">
                    <ul class="mypage-navigation__level mypage-navigation__level--1">
                        <li><router-link to="/mypage/order-list" exact-active-class="" active-class="on" class="mypage-navigation__level-link"><span>전체주문내역</span></router-link></li>
                        <li><router-link to="/mypage/order-cancel" exact-active-class="" active-class="on" class="mypage-navigation__level-link"><span>취소내역</span></router-link></li>
                        <li><router-link to="/mypage/order-exchange" exact-active-class="" active-class="on" class="mypage-navigation__level-link"><span>교환내역</span></router-link></li>
                        <li><router-link to="/mypage/order-return" exact-active-class="" active-class="on" class="mypage-navigation__level-link"><span>반품내역</span></router-link></li>
                    </ul>
                </div>
            </li> -->
            <!-- <li class="mypage-navigation__list">
                <router-link to="/mypage/myinfo" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>정보수정</span></router-link>
            </li> -->
            <!-- <li class="mypage-navigation__list">
                <router-link to="/mypage/notice" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>공지사항</span></router-link>
            </li> -->
            <!-- <li class="mypage-navigation__list">
                <router-link to="/mypage/estimate" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>견적신청목록</span></router-link>
            </li> -->
            <!-- <li class="mypage-navigation__list">
                <router-link to="/mypage/coupon" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>쿠폰내역</span></router-link>
            </li> -->
            <!-- <li class="mypage-navigation__list">
                <router-link to="/mypage/point" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>적립금</span></router-link>
            </li> -->
            <li class="mypage-navigation__list">
                <router-link to="/mypage/myinfo" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>{{$t("common.기업정보")}}</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/fairs" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>{{$t("common.매칭상담회")}}</span></router-link>
            </li>
            <li v-if="scope.includes('seller')" class="mypage-navigation__list">
                <router-link to="/mypage/products" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>{{$t("common.상품관리")}}</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/question" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>{{$t("common.1:1 문의")}}</span></router-link>
            </li>
        </ul>
    </nav>
</template>
<script>
export default{
    data() {
        return{
            //  화살표 아이콘
            icon: '<svg x="0px" y="0px" viewBox="0 0 9.7 6.4" style="fill:none;stroke:#333333;stroke-width:2;stroke-linecap:round;"><path d="M1,1.4l3.8,4l3.9-4"/></svg>',
        }
    },
    methods : {
        accordionToggle(event){
            var button = event.currentTarget,
                contents = button.nextSibling;
            var height = 0;

            if(button.getAttribute('data-toggle') === 'closed'){
                contents.style.maxHeight = 'none';
                height = contents.clientHeight;
                contents.style.maxHeight = '0';
                setTimeout(function(){ contents.style.maxHeight = height + 'px'; }, 1);
                button.setAttribute('data-toggle', 'opened');
                contents.ontransitionend = () => {
                    contents.removeAttribute('style');
                };
            }else{
                height = contents.clientHeight;
                contents.style.maxHeight = height + 'px';
                setTimeout(function(){ contents.style.maxHeight = '0px'; }, 1);
                button.setAttribute('data-toggle', 'closed');
                contents.ontransitionend = () => {
                    contents.removeAttribute('style');
                };
            }
        }
    },
    computed: {
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload.scope || [];
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.mypage-navigation{
    list-style: none; border-top: 2px solid #474747;
    .on { position: relative; font-weight: 500; color: $color-primary; }
    &-container { padding-right: 50px; width: 240px; }
	&__link { display: block; position: relative; padding: 17px 0; font-size: 18px; line-height: 1.2; border-bottom: 1px solid #dddddd; cursor: pointer; user-select: none; }
    &__link[data-toggle] { user-select: none; }
	&__link[data-toggle] .arrow { display: flex; align-items: center; justify-content: center; position: absolute; top: 0; right: 0; bottom: 0; margin: auto; width: 11px; height: 20px; }
    &__link[data-toggle] .arrow::v-deep svg { display: block; width: 11px; height: 7px; pointer-events: none; }
	&__link[data-toggle="opened"] { border-bottom-style: dashed; }
	&__link[data-toggle="opened"] .arrow { -webkit-transform: scaleY(-1); transform: scaleY(-1); }
	&__link[data-toggle="closed"] + &__sub { max-height: 0; }
    &__sub { overflow: hidden; transition: all ease .2s; }
	&__level{
        list-style: none;
        &--1 { padding: 15px 0 5px; border-bottom: 1px solid #dddddd; }
        &-link { display: block; padding: 0 10px; height: 30px; line-height: 1; font-size: 18px; color: #555555; font-weight: 300; cursor: pointer; user-select: none; }
        &-link[data-toggle="opened"] { color: $color-primary; font-weight: 700; }
        &-link[data-toggle="opened"] + .mypage-navigation__level { display: block; }
    }
}
@media(max-width:1024px){
    .mypage-navigation{
        display: flex; scrollbar-width: none; overflow-x: auto; overflow-y: hidden;
        position: relative; padding-bottom: 45px; white-space: nowrap;
        border-top: none; border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        .on { border-color: $color-primary; }
        .on::before { content: ''; position: absolute; bottom: 0; left: 0; width: 100%; height: 1px; background: $color-primary; }
        &-container { padding: 0 0 35px; width: 100%; }
        &::-webkit-scrollbar { display: none; }
        &__list { flex: 1 1; }
        &__link { text-align: center; font-size: 14px; }
        &__list-link { margin-right: 18px; padding: 18px 0 0; height: 50px; font-size: 14px; color: #777777; font-weight: 400; border-bottom: none; }
        &__list-link span { display: block; padding-bottom: 8px; border-bottom: 1px solid transparent; }
        &__list-link[data-toggle] .arrow { display: none; }
        &__list-link[data-toggle="opened"] { border-bottom: none; color: #333333; font-weight: 500; }
        &__list-link[data-toggle="opened"] span { border-bottom-color: #333333; }
        &__level{
            &--1 { display: none; align-items: center; position: absolute; bottom: 0; left: 0; width: 100%; height: 50px; }
            &--1.active { display: flex; }
            &-link { margin-right: 18px; padding: 0; font-size: 14px; }
        }
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "mypage-navigation-container"
  }, [_c('ul', {
    staticClass: "mypage-navigation"
  }, [_c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/myinfo",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.기업정보")))])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/fairs",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.매칭상담회")))])])], 1), _vm.scope.includes('seller') ? _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/products",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.상품관리")))])])], 1) : _vm._e(), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/question",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.1:1 문의")))])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
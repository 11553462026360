<template>
    <div role="table" class="myinfo-table myinfo-table--cell-background mt-4">
        <div role="rowgroup">

            <myinfo-row v-show="locale == 'ko'" label="기업구분">
                {{ me.kind }}
            </myinfo-row>
            
            <myinfo-row v-show="locale == 'ko'" label="카테고리">
                <v-checkbox v-model="me.category" v-for="category in categories" :key="category._id" color="primary" :label="category.name[locale]" :value="category._id" dense hide-details class="d-inline-block mr-3 mt-0" @input="input"></v-checkbox>
            </myinfo-row>

            <myinfo-row :label="$t('common.기업명')">
                {{ me.companyName[locale] }}
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="사업자등록번호">
                <v-text-field v-model="me.businessNumber" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row :label="$t('common.주소')">
                <v-row>
                    <v-col cols="auto">
                        <v-text-field v-model="me.sido[locale]" :label="$t('common.시도')" outlined hide-details @input="input"></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-text-field v-model="me.gugun[locale]" :label="$t('common.시구군')" outlined hide-details @input="input"></v-text-field>
                    </v-col>
                    <v-col v-show="locale == 'ko'">
                        <v-text-field v-model="me.companyAddress" label="상세주소" outlined hide-details @input="input"></v-text-field>
                    </v-col>
                </v-row>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="수출기업여부">
                <v-radio-group v-model="me.isExportCompany" row>
                    <v-radio label="수출기업" :value="true"></v-radio>
                    <v-radio label="비수출" :value="false"></v-radio>
                </v-radio-group>
            </myinfo-row>

            <myinfo-row :label="$t('mypage.CEO_name')">
                <v-text-field v-model="me.ceoName[locale]" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="대표자 연락처">
                <v-text-field v-model="me.ceoPhone" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="대표자 이메일">
                <v-text-field v-model="me.ceoEmail" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>
            
            <myinfo-row :label="$t('mypage.company_profile')">
                <v-row align="center">
                    <v-col md="6">
                        <v-file-input v-model="me.introductions[0][locale]" outlined hide-details @input="input" accept=".pdf,.xlsx,.jpg,.png"></v-file-input>
                    </v-col>
                    <v-col cols="auto" v-if="me.introductions[0][locale]">
                        <span>{{ me.introductions[0][locale].name.split('/').pop() }}</span>
                        <v-icon @click="removeIntroduction(0, locale)">mdi-delete</v-icon>
                    </v-col>
                </v-row>
                <small class="d-block text-grey caption">{{$t("mypage.company_profile_upload")}}</small>
                <div class="text-explain mb-2"><small>{{$t("mypage.company_profile_warning")}}</small></div>
            </myinfo-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import MyinfoRow from "./myinfo-row.vue";
export default {
    components: {
        MyinfoRow
    },
    props: ["value", "locale"],
    data() {
        return {
            me: this.$props.value,

            categories: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        input(){
            this.$emit("input", this.me);
        },
        async removeIntroduction(index, locale){
            await api.v1.me.introductions.delete(index, locale);
            this.me.introductions[index][locale] = null;
        }
    },
    watch: {
        value(){
            this.me = this.value;
        }
    }
}
</script>
<template>
    <div class="sub-visual">
        <div class="sub-visual__wrapper">
            <h3 class="sub-visual__title"><span>{{visual}}</span></h3>
        </div>
	</div>
</template>

<script>

export default{
    props : {
        visual : {type :String, default : ""}
    },
	components: {

	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.sub-visual{
    margin-bottom: 140px; padding: 135px 0 60px; background: $background;
    
    &__wrapper { margin: auto; max-width: $container; padding: 0 15px; width: 100%; }
    &__title { line-height: 1; font-size: 48px; color: #fff; font-weight: 700; }
    &__title span { display: inline-block; position: relative; }
    &__title span::after{
        content: ''; position: absolute; bottom: 0; right: -18px;
        width: 8px; height: 8px; background: #00a4e0; border-radius: 50%;
    }
}
@media(max-width:1200px){
    .sub-visual {
        margin-bottom: 120px; padding: 110px 0 50px;

        &__title { font-size: 38px; }
    }
}
@media(max-width:768px){
    .sub-visual {
        margin-bottom: 80px; padding: 70px 0 35px;

        &__title { font-size: 24px; }
    }
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "myinfo-table myinfo-table--cell-background mt-4",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('myinfo-row', {
    attrs: {
      "label": _vm.$t('common.기업명')
    }
  }, [_vm._v(" " + _vm._s(_vm.me.companyName[_vm.locale]) + " ")]), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "주소"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "시/도",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.sido,
      callback: function ($$v) {
        _vm.$set(_vm.me, "sido", $$v);
      },
      expression: "me.sido"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "시/구/군",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.gugun,
      callback: function ($$v) {
        _vm.$set(_vm.me, "gugun", $$v);
      },
      expression: "me.gugun"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "상세주소",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.me, "companyAddress", $$v);
      },
      expression: "me.companyAddress"
    }
  })], 1)], 1)], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "바이어 유형"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.kind.join(",")) + " ")]), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "설립연도"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.foundedAt,
      callback: function ($$v) {
        _vm.$set(_vm.me, "foundedAt", $$v);
      },
      expression: "me.foundedAt"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "사업분야"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.businessAreas,
      callback: function ($$v) {
        _vm.$set(_vm.me, "businessAreas", $$v);
      },
      expression: "me.businessAreas"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "회사 소재국가"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.countryOfOrigin) + " ")]), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('mypage.buyer_activity_area')
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.activityAreas[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.activityAreas, _vm.locale, $$v);
      },
      expression: "me.activityAreas[locale]"
    }
  }, [_vm._v("?")])], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "대표자 이름"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.ceoName,
      callback: function ($$v) {
        _vm.$set(_vm.me, "ceoName", $$v);
      },
      expression: "me.ceoName"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "대표자 연락처"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.ceoPhone,
      callback: function ($$v) {
        _vm.$set(_vm.me, "ceoPhone", $$v);
      },
      expression: "me.ceoPhone"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "대표자 이메일"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.ceoEmail,
      callback: function ($$v) {
        _vm.$set(_vm.me, "ceoEmail", $$v);
      },
      expression: "me.ceoEmail"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "주요 유통망(온라인)"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.onlineCirculation,
      callback: function ($$v) {
        _vm.$set(_vm.me, "onlineCirculation", $$v);
      },
      expression: "me.onlineCirculation"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "주요 유통망(오프라인)"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.offlineCirculation,
      callback: function ($$v) {
        _vm.$set(_vm.me, "offlineCirculation", $$v);
      },
      expression: "me.offlineCirculation"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "회사소개"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.aboutUs,
      callback: function ($$v) {
        _vm.$set(_vm.me, "aboutUs", $$v);
      },
      expression: "me.aboutUs"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "관심상품군"
    }
  }, _vm._l(_vm.categories, function (category) {
    return _c('v-checkbox', {
      key: category._id,
      staticClass: "d-inline-block mr-3 mt-0",
      attrs: {
        "color": "primary",
        "label": category.name[_vm.$i18n.locale],
        "value": category._id,
        "dense": "",
        "multiple": "",
        "hide-details": ""
      },
      on: {
        "input": _vm.input
      },
      model: {
        value: _vm.me.interestedGroups,
        callback: function ($$v) {
          _vm.$set(_vm.me, "interestedGroups", $$v);
        },
        expression: "me.interestedGroups"
      }
    });
  }), 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "소싱 희망상품"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.sourcings,
      callback: function ($$v) {
        _vm.$set(_vm.me, "sourcings", $$v);
      },
      expression: "me.sourcings"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "주요경력"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.career,
      callback: function ($$v) {
        _vm.$set(_vm.me, "career", $$v);
      },
      expression: "me.career"
    }
  })], 1), _c('myinfo-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locale == 'ko',
      expression: "locale == 'ko'"
    }],
    attrs: {
      "label": "홈페이지 주소"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.homepage,
      callback: function ($$v) {
        _vm.$set(_vm.me, "homepage", $$v);
      },
      expression: "me.homepage"
    }
  })], 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('mypage.company_profile') + '1'
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "accept": ".pdf,.xlsx,.jpg,.png"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.introductions[0][_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.introductions[0], _vm.locale, $$v);
      },
      expression: "me.introductions[0][locale]"
    }
  })], 1), _vm.me.introductions[0][_vm.locale] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.me.introductions[0][_vm.locale].name.split('/').pop()))]), _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.removeIntroduction(0, _vm.locale);
      }
    }
  }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1), _c('small', {
    staticClass: "d-block text-grey caption"
  }, [_vm._v(_vm._s(_vm.$t('mypage.company_profile_upload')))]), _c('div', {
    staticClass: "text-explain mb-2"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('mypage.company_profile_warning')))])])], 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('mypage.company_profile') + '2'
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "accept": ".pdf,.xlsx,.jpg,.png"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.introductions[1][_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.introductions[1], _vm.locale, $$v);
      },
      expression: "me.introductions[1][locale]"
    }
  })], 1), _vm.me.introductions[1][_vm.locale] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.me.introductions[1][_vm.locale].name.split('/').pop()))]), _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.removeIntroduction(1, _vm.locale);
      }
    }
  }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1), _c('small', {
    staticClass: "d-block text-grey caption"
  }, [_vm._v(_vm._s(_vm.$t('mypage.company_profile_upload')))]), _c('div', {
    staticClass: "text-explain mb-2"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('mypage.company_profile_warning')))])])], 1), _c('myinfo-row', {
    attrs: {
      "label": _vm.$t('mypage.company_profile') + '3'
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "accept": ".pdf,.xlsx,.jpg,.png"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.me.introductions[2][_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.me.introductions[2], _vm.locale, $$v);
      },
      expression: "me.introductions[2][locale]"
    }
  })], 1), _vm.me.introductions[2][_vm.locale] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.me.introductions[2][_vm.locale].name.split('/').pop()))]), _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.removeIntroduction(2, _vm.locale);
      }
    }
  }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1), _c('small', {
    staticClass: "d-block text-grey caption"
  }, [_vm._v(_vm._s(_vm.$t('mypage.company_profile_upload')))]), _c('div', {
    staticClass: "text-explain mb-2"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('mypage.company_profile_warning')))])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
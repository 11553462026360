<template>
	<client-page class="wrap wrap--mypage">
        <!-- 서브비주얼 -->
        <sub-visual v-if="scope.includes('seller')" :visual="$t('common.셀러 회원 마이페이지')"></sub-visual>
        <sub-visual v-if="scope.includes('buyer')" :visual="$t('common.바이어 회원 마이페이지')"></sub-visual>

		<div class="contents">
			<div class="ct">
				<div class="mypage-container">
					<!-- 네비게이션 메뉴 -->
					<mypage-navigation></mypage-navigation>
					<!-- 페이지 콘텐츠 -->
					<div v-if="me" class="mypage-contents">
                        <div class="text-explain mb-5">
                            <p class="color-primary">{{$t('mypage.company_edit_message1')}} <strong>{{$t('mypage.save')}}</strong> {{$t('mypage.company_edit_message2')}}</p>
                            <small>{{$t('mypage.company_profile_warning')}}</small>
                        </div>
						<div class="mypage-contents__head">
							<!-- <h2 class="mypage-contents__title">회원정보수정</h2> -->
							<div class="tab-menu">
								<tab-item
									v-for="item in list"
									v-bind="item" :key="item.id"
									v-model="currentId"/>
							</div>
						</div>
						<div class="mypage-contents__body">
							<div class="table-caption-right">
								<span class="color-red">*</span> {{$t('common.required_fields')}}
							</div>
							<div v-show="locale == 'ko'" role="table" class="myinfo-table myinfo-table--cell-background">
								<div role="rowgroup">
									<myinfo-row label="아이디">
										{{ me.username }}
									</myinfo-row>

									<myinfo-row label="담당자 이름">
										<v-text-field v-model="me.name" type="text" class="input" outlined hide-details></v-text-field>
									</myinfo-row>

									<myinfo-row label="담당자 연락처">
										<div class="form-tel">
											<v-select v-model="me.phone1" :items="['010','011','016','017','019']" outlined hide-details></v-select>
											<span class="text"></span>
											<v-text-field v-model="me.phone2" maxlength="4" outlined hide-details @input="me.phone2 = me.phone2.replace(/[^0-9]/g, '')"></v-text-field>
											<span class="text"></span>
											<v-text-field v-model="me.phone3" maxlength="4" outlined hide-details @input="me.phone2 = me.phone2.replace(/[^0-9]/g, '')"></v-text-field>
										</div>
									</myinfo-row>

									<myinfo-row label="담당자 이메일">
										<v-text-field v-model="me.email" type="text" class="input" outlined hide-details></v-text-field>
									</myinfo-row>

									<myinfo-row label="비밀번호" pointer>
										<v-text-field v-model="me.subPassword" type="password" name="" value="" outlined hide-details placeholder="비밀번호를 입력하세요."></v-text-field>
										<small class="d-block text-grey caption">(영문대소문자/숫자/특수문자 조합, 8 - 16자)</small>
									</myinfo-row>

									<myinfo-row label="비밀번호 확인" pointer>
										<v-text-field v-model="me.subPassword2" type="password" name="" value="" outlined hide-details placeholder="비밀번호를 재입력하세요."></v-text-field>
									</myinfo-row>

									<myinfo-row v-show="false" label="주소" pointer>
										<div class="form-address">
											<div class="form-address__postcode">
												<v-text-field v-model="me.postcode" type="text" readonly placeholder="우편번호" outlined hide-details @click="$refs.daumPostcode.open()"></v-text-field>
												<button class="button button--primary" @click="$refs.daumPostcode.open()">검색</button>
											</div>
											<v-text-field v-model="me.address1" type="text" readonly placeholder="기본 주소를 입력해주세요." outlined hide-details @click="$refs.daumPostcode.open()"></v-text-field>
											<v-text-field v-model="me.address2" type="text" placeholder="상세 주소를 입력해주세요." outlined hide-details></v-text-field>
										</div>
									</myinfo-row>
								</div>
							</div>

							<buyer-info v-if="scope.includes('buyer')" v-model="me" :locale="locale"></buyer-info>
							<seller-info v-if="scope.includes('seller')" v-model="me" :locale="locale"></seller-info>

							<div class="bottom-button">
								<button class="button button--border-primary"><span>{{$t("common.취소")}}</span></button>
								<button class="button button--primary" @click="save"><span>{{$t("common.저장")}}</span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <daum-postcode ref="daumPostcode" @change="({ postcode, address }) => Object.assign(me, { postcode, address1: address })"></daum-postcode>
	</client-page>
</template>

<script>
import api from "@/api";

import CryptoAES from "@/plugins/crypto-aes";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";

import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import TabItem from "@/components/client/tab/tab-item.vue";

import BuyerInfo from "@/components/client/mypage/buyer-info.vue";
import SellerInfo from "@/components/client/mypage/seller-info.vue";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

export default {
	components: {
		ClientPage,
		SubVisual,
		MypageNavigation,
        DaumPostcode,

		MyinfoRow,
		BuyerInfo,
		SellerInfo,

        TabItem,
	},
	data() {
		return {
			me: null,
			categories: [],

			currentId: ["ko", "en", "cn"].indexOf(this.$i18n.locale) + 1,
			list: [
				{ id: 1, label: "한국어", locale: "ko" },
				{ id: 2, label: "영어", locale: "en" },
				{ id: 3, label: "중국어", locale: "cn" },
			],

			activityAreas: [
				{ text: "국내 유통망", value: "국내 유통망"},
				{ text: "일본", value: "일본"},
				{ text: "중국", value: "중국"},
				{ text: "동남아", value: "동남아"},
				{ text: "남미", value: "남미"},
				{ text: "북미", value: "북미"},
				{ text: "유럽", value: "유럽"},
				{ text: "기타", value: "기타"},
			]

		};
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		async init(){
			try{
				if(!this.accessToken){
					this.$router.push({
						path: '/login',
						params: this.$router.params
					});
					return;
				}

                var { user } = await api.v1.me.get();
                var [phone1, phone2, phone3] = (user.phone || '').phoneNumberFormat().split('-');

				for(let introduction of user.introductions){
					if(introduction.ko) introduction.ko = await api.getResource(introduction.ko);
					if(introduction.en) introduction.en = await api.getResource(introduction.en);
					if(introduction.cn) introduction.cn = await api.getResource(introduction.cn);
				}

                this.me = {
                    ...user,
                    phone1, phone2, phone3
                }

				let { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
				this.categories = categories;
			}
			catch(error){
				console.error(error);
				alert(error.response ? error.response.data.message : error.message);
			}
		},

		checkPassword(password) {
			var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
			var checkEng = /[a-zA-Z]/;
			var checkNum = /[0-9]/;

			if(!checkSpecial.test(password)) throw new Error('비밀번호에 특수문자가 포함되어있어야합니다.');
			if(!checkEng.test(password)) throw new Error('비밀번호에 영문이 포함되어있어야합니다.');
			if(!checkNum.test(password)) throw new Error('비밀번호에 숫자가 포함되어있어야합니다.');
			if(password.length < 8 || 16 < password.length) throw new Error('비밀번호는 8~16자까지입니다.');
		},

		validate(){
			try{
				if((this.me.subPassword || this.me.subPassword2) && (this.me.subPassword != this.me.subPassword2)) throw new Error("비밀번호 및 비밀번호확인이 일치하지 않습니다");
				if(this.me.subPassword && this.me.subPassword2) this.checkPassword(this.me.subPassword);

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},

		async save(){
			if(this.validate()){
				await api.v1.me.put({
                    ...this.me,
                    subPassword: this.me.subPassword ? CryptoAES.encrypt(this.me.subPassword): undefined,
                    subPassword2: undefined
                });

				for(let introduction of this.me.introductions){
					if(introduction.ko) api.v1.me.introductions.post(this.me.introductions.indexOf(introduction), this.locale, introduction.ko);
					if(introduction.en) api.v1.me.introductions.post(this.me.introductions.indexOf(introduction), this.locale, introduction.en);
					if(introduction.cn) api.v1.me.introductions.post(this.me.introductions.indexOf(introduction), this.locale, introduction.cn);
				}

				alert("수정되었습니다");
			}
		},
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		},
		phone(){
			return (this.phone1 && this.phone2 && this.phone3) ? [this.phone1, this.phone2, this.phone3].join('-') : undefined;
		},
		payload(){
			return this.$store.state.payload || {};
		},
		scope(){
			return this.payload.scope || [];
		},

		locale(){
			return this.list.find(({ id }) => this.currentId == id).locale;
		},

		kinds(){
			if(this.scope.includes('seller')){
				// 나중에 다국어 작업시 text만 번역하고, value는 변경 X
				return [
					{ text: "서울어워드", value: "서울어워드" },
					{ text: "슈퍼루키", value: "슈퍼루키" },
					{ text: "기타", value: "기타" },
				];
			}
			if(this.scope.includes('buyer')){
				// 나중에 다국어 작업시 text만 번역하고, value는 변경 X
				return [
					{ text: "인플루언서", value: "인플루언서" },
					{ text: "유통플랫폼", value: "유통플랫폼" },
					{ text: "수출", value: "수출" },
					{ text: "국제무역인", value: "국제무역인" },
					{ text: "해외플랫폼", value: "해외플랫폼" },
					{ text: "기타", value: "기타" },
				];
			}
			return [];
		}
	},
    watch: {
        "me.phone1"(){ var { phone1, phone2, phone3 } = this.me; this.me.phone = [phone1, phone2, phone3].join('-') },
        "me.phone2"(){ var { phone1, phone2, phone3 } = this.me; this.me.phone = [phone1, phone2, phone3].join('-') },
        "me.phone3"(){ var { phone1, phone2, phone3 } = this.me; this.me.phone = [phone1, phone2, phone3].join('-') },
    }
}
</script>

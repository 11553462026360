<template>
    <a @click="$emit('input', id)" :class="[active, 'item']" class="swiper-slide">
        {{ label }}
    </a>
</template>

<script>
export default {
    props: {
        id: Number,
        label: String,
        value: Number,
    },
    computed: {
        active() {
            return this.value === this.id ? "active" : false;
        },
    },
};
</script>

<style lang="scss" scorped>
@import "@/styles/_variables.scss";

.tab-menu{
    overflow: hidden; display: flex;
    line-height: 1; text-align: center; font-size: 18px; color: #666666;
    border-radius: 10px; background: #f9f9f9;
    
    .swiper-container { width: 100%; }
    .item { flex: 1 1; display: flex; align-items: center; justify-content: center; padding: 18px; border-radius: 10px; transition: background-color ease .3s; }
    .item.active { color: #fff; font-weight: 700; background-color: $color-primary; }
    .item:not(.active):hover {
        background: #eeeeee;
    }
}
@media(max-width:1200px){
  .tab-menu{
      font-size: 17px;

      .item { padding: 16px; }
  }  
}
@media(max-width:768px){
    .tab-menu{
        margin: 0 -15px; font-size: 16px; border-radius: 6px;

        .item { padding: 12px 16px; border-radius: 6px; white-space: nowrap; }
    }  
}
</style>
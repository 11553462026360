<template>
    <div role="table" class="myinfo-table myinfo-table--cell-background mt-4">
        <div role="rowgroup">
            <myinfo-row :label="$t('common.기업명')">
                {{ me.companyName[locale] }}
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="주소">
                <v-row>
                    <v-col cols="auto">
                        <v-text-field v-model="me.sido" label="시/도" outlined hide-details @input="input"></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-text-field v-model="me.gugun" label="시/구/군" outlined hide-details @input="input"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="me.companyAddress" label="상세주소" outlined hide-details @input="input"></v-text-field>
                    </v-col>
                </v-row>
            </myinfo-row>
            
            <myinfo-row v-show="locale == 'ko'" label="바이어 유형">
                {{ me.kind.join(",") }}
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="설립연도">
                <v-text-field v-model="me.foundedAt" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="사업분야">
                <v-text-field v-model="me.businessAreas" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="회사 소재국가">
                {{ me.countryOfOrigin }}
            </myinfo-row>

            <myinfo-row :label="$t('mypage.buyer_activity_area')">
                <v-text-field v-model="me.activityAreas[locale]" outlined hide-details @input="input">?</v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="대표자 이름">
                <v-text-field v-model="me.ceoName" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="대표자 연락처">
                <v-text-field v-model="me.ceoPhone" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="대표자 이메일">
                <v-text-field v-model="me.ceoEmail" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="주요 유통망(온라인)">
                <v-text-field v-model="me.onlineCirculation" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="주요 유통망(오프라인)">
                <v-text-field v-model="me.offlineCirculation" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="회사소개">
                <v-text-field v-model="me.aboutUs" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="관심상품군">
                <v-checkbox v-model="me.interestedGroups" v-for="category in categories" :key="category._id" color="primary" :label="category.name[$i18n.locale]" :value="category._id" dense multiple hide-details class="d-inline-block mr-3 mt-0" @input="input"></v-checkbox>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="소싱 희망상품">
                <v-text-field v-model="me.sourcings" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="주요경력">
                <v-text-field v-model="me.career" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row v-show="locale == 'ko'" label="홈페이지 주소">
                <v-text-field v-model="me.homepage" outlined hide-details @input="input"></v-text-field>
            </myinfo-row>

            <myinfo-row :label="$t('mypage.company_profile') + '1'">
                <v-row align="center">
                    <v-col md="6">
                        <v-file-input v-model="me.introductions[0][locale]" outlined hide-details @input="input" accept=".pdf,.xlsx,.jpg,.png"></v-file-input>
                    </v-col>
                    <v-col cols="auto" v-if="me.introductions[0][locale]">
                        <span>{{ me.introductions[0][locale].name.split('/').pop() }}</span>
                        <v-icon @click="removeIntroduction(0, locale)">mdi-delete</v-icon>
                    </v-col>
                </v-row>
                <small class="d-block text-grey caption">{{$t('mypage.company_profile_upload')}}</small>
                <div class="text-explain mb-2"><small>{{$t('mypage.company_profile_warning')}}</small></div>
            </myinfo-row>
            <myinfo-row :label="$t('mypage.company_profile') + '2'">
                <v-row align="center">
                    <v-col md="6">
                        <v-file-input v-model="me.introductions[1][locale]" outlined hide-details @input="input" accept=".pdf,.xlsx,.jpg,.png"></v-file-input>
                    </v-col>
                    <v-col cols="auto" v-if="me.introductions[1][locale]">
                        <span>{{ me.introductions[1][locale].name.split('/').pop() }}</span>
                        <v-icon @click="removeIntroduction(1, locale)">mdi-delete</v-icon>
                    </v-col>
                </v-row>
                <small class="d-block text-grey caption">{{$t('mypage.company_profile_upload')}}</small>
                <div class="text-explain mb-2"><small>{{$t('mypage.company_profile_warning')}}</small></div>
            </myinfo-row>
            <myinfo-row :label="$t('mypage.company_profile') + '3'">
                <v-row align="center">
                    <v-col md="6">
                        <v-file-input v-model="me.introductions[2][locale]" outlined hide-details @input="input" accept=".pdf,.xlsx,.jpg,.png"></v-file-input>
                    </v-col>
                    <v-col cols="auto" v-if="me.introductions[2][locale]">
                        <span>{{ me.introductions[2][locale].name.split('/').pop() }}</span>
                        <v-icon @click="removeIntroduction(2, locale)">mdi-delete</v-icon>
                    </v-col>
                </v-row>
                <small class="d-block text-grey caption">{{$t('mypage.company_profile_upload')}}</small>
                <div class="text-explain mb-2"><small>{{$t('mypage.company_profile_warning')}}</small></div>
            </myinfo-row>

                
        </div>
    </div>
</template>

<script>
import api from "@/api";
import MyinfoRow from "./myinfo-row.vue";
export default {
    components: {
        MyinfoRow
    },
    props: ["value", "locale"],
    data() {
        return {
            me: this.$props.value,
            categories: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        input(){
            this.$emit("input", this.me);
        },
        async removeIntroduction(index, locale){
            await api.v1.me.introductions.delete(index, locale);
            this.me.introductions[index][locale] = undefined;
        }
    },
    watch: {
        value(){
            this.me = this.value;
        }
    }
}
</script>